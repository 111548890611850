/** @jsx jsx */
/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';

import theme from '../theme';
import useSiteMetadata from '../hooks/useSiteMetaData';
import Menu from './Menu';
import MobileMenu from './MobileMenu';

const rootStyle = css`
  height: 100vh;
  position: relative;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const scrollStyle = css`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
`;

type Props = {
  children: React.ReactNode
};

function MainContainer(props: Props): React.ReactElement {
  const { children } = props;

  const { title, description } = useSiteMetadata();
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  // Lazily showing mobile menu because it's dependent on viewport calculations which are not available during SRR
  React.useEffect(() => {
    setShowMobileMenu(true);
  }, []);

  return (
    <div css={rootStyle}>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content={theme.palette.primary.main} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      { /* @ts-ignore */}
      <Menu />
      <div css={scrollStyle} ref={scrollRef}>
        {children}
      </div>
      {showMobileMenu && <MobileMenu scrollContainerRef={scrollRef} />}
    </div>
  );
}

export default MainContainer;
