/** @jsx jsx */
import * as React from 'react';
import { Menu, X } from 'react-feather';
import { jsx, css } from '@emotion/react';
import { Link } from 'gatsby';
import theme from '../theme';
import useViewport from './useViewport';
import menu, { MenuEntry } from '../menu';

const ICON_SIZE = 40;

const iconStyle = css`
    position: absolute;
    top: 32px;
    right: 32px;
    transition: all 200ms;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    ${theme.breakpoints.up('md')} {
        display: none;
    }
`;

const backgroundStyle = css`
    background-color: ${theme.palette.common.white}55;
    border-radius: 5px;
`;

const backgroundOpenStyle = (maxScale: number) => css`
    background-color: ${theme.palette.primary.light};
    transform: scale(${maxScale});
    transition: all 400ms;
`;

const iconInvisibleStyle = css`
    opacity: 0;
    pointer-events: none;
`;

const containerStyle = css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const menuStyle = css`
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: -8px;
`;

const itemStyle = css`
    margin-top: 8px;
    list-style-type: none;
    a {
      text-decoration: none;
      color: ${theme.palette.common.black}
    }
`;

type Props = {
  scrollContainerRef: React.RefObject<HTMLDivElement | undefined>
};

function MobileMenu(props: Props): React.ReactElement {
  const { scrollContainerRef } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<boolean>(true);
  const closeMenu = React.useCallback(() => setOpen(false), []);
  const previousScroll = React.useRef<number>(scrollContainerRef.current?.scrollTop ?? 0);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const viewportSize = useViewport();
  const maxScale = 2 * Math.max(viewportSize.width / ICON_SIZE, viewportSize.height / ICON_SIZE);

  React.useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    function handleScroll() {
      if (scrollContainer) {
        const currentScroll = scrollContainer.scrollTop;
        if (menuRef.current) {
          setVisible(previousScroll.current > currentScroll);
        }
        previousScroll.current = currentScroll;
      }
    }
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => scrollContainer.removeEventListener('scroll', handleScroll);
    }
    return undefined;
  }, [scrollContainerRef]);

  function renderMenuEntry(menuEntry: MenuEntry) {
    return (
      <li css={itemStyle}>
        <Link to={menuEntry.uri}>
          {menuEntry.label}
        </Link>
      </li>
    );
  }

  return (
    <React.Fragment>
      <div
        ref={menuRef}
        css={[iconStyle, backgroundStyle, !visible && iconInvisibleStyle, open && backgroundOpenStyle(maxScale)]}
      />
      {open && (
        <div css={containerStyle}>
          <ul css={menuStyle}>
            {menu.map(renderMenuEntry)}
          </ul>
        </div>
      )}
      <div css={iconStyle}>
        {open ? <X onClick={closeMenu} /> : <Menu css={!visible && iconInvisibleStyle} onClick={() => setOpen(true)} />}
      </div>
    </React.Fragment>
  );
}

export default MobileMenu;
