/** @jsx jsx */
import * as React from 'react';
import { jsx } from '@emotion/react';
import theme, { Typography } from '../theme';

const COMPONENTS = {
  h1: 'h1',
  h2: 'h2',
} as const;

type ComponentType = keyof typeof COMPONENTS | 'span' | 'p';

type Props = {
  children: React.ReactNode,
  variant?: Typography,
  className?: string,
};

function Text(props: Props): React.ReactElement {
  const {
    children, variant, className,
  } = props;

  const Component: ComponentType = (variant ? COMPONENTS[variant] : 'p') || 'span';
  const typographyStyle = variant && theme.typography[variant];

  return (
    <Component className={className} css={typographyStyle}>
      {children}
    </Component>
  );
}

Text.defaultProps = {
  className: undefined,
  variant: undefined,
};

export default Text;
