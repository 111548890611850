/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';

import { Link } from 'gatsby';
import theme from '../theme';
import menu, { MenuEntry } from '../menu';

const containerStyle = css`
  ${theme.breakpoints.down('md')} {
    display: none;
  }
  display: flex;
  justify-content: space-around;
  padding: 16px 0;
  margin-block-end: 0;
  margin-block-start: 0;
`;

const itemStyle = css`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-size: 1.1em;
`;

type Props = {
  className?: string
};

function Menu(props: Props): React.ReactElement {
  const { className } = props;

  function renderMenuEntry(menuEntry: MenuEntry) {
    return (
      <Link css={itemStyle} key={menuEntry.key} to={menuEntry.uri}>
        {menuEntry.label}
      </Link>
    );
  }

  return (
    <ul css={containerStyle} className={className}>
      {menu.map(renderMenuEntry)}
    </ul>
  );
}

Menu.defaultProps = {
  className: undefined,
};

export default Menu;
