export interface MenuEntry {
  key: string
  label: string
  uri: string
}

const menu: MenuEntry[] = [
  {
    key: 'home',
    label: 'Home',
    uri: '/',
  },
  {
    key: 'about',
    label: 'About',
    uri: '/about-us',
  },
  {
    key: 'media',
    label: 'Media',
    uri: '/media',
  },

];

export default menu;
