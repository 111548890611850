/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';

type ViewportSize = {
  width: number,
  height: number,
};

function useViewport(): ViewportSize {
  const [size, setSize] = React.useState<ViewportSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  React.useLayoutEffect(() => {
    function handleUiEvent(ev: UIEvent) {
      if (ev.target) {
        setSize({
          // @ts-ignore
          width: ev.target.innerWidth,
          // @ts-ignore
          height: ev.target.innerHeight,
        });
      }
    }
    window.addEventListener('resize', handleUiEvent);
    return () => window.removeEventListener('resize', handleUiEvent);
  });
  return size;
}

export default useViewport;
